import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
// import ReactPlayer from 'react-player/lazy';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Button,
} from '@material-ui/core';

import { WaveUpHero } from '../../WaveSVGs/WaveUpHero';

const Video = loadable(() => import('../../Home/Hero/Video'));

const useStyles = makeStyles((theme) => ({
  '@keyframes pulseBorder': {
    '0%': {
      transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)',
      opacity: '1',
    },
    '100%': {
      transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)',
      opacity: '0',
    },
  },

  root: {
    position: 'relative',
    width: '100%',
    padding: '6rem 0',
    maxWidth: '100vw',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
  },
  bgWhiteLight: {
    width: '100%',
    height: '200%',
    background: '#F8FBFF',
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    marginLeft: '25%',
    boxShadow: '4px 4px 6px rgb(50 50 93 / 8%), 0 1px 3px rgb(0 0 0 / 6%)',
    [theme.breakpoints.down('sm')]: {
      top: '0',
      left: '0',
      marginLeft: '0',
    },
  },
  playButton: {
    height: '100px',
    width: '100px',
		minWidth: '100px',
    background: theme.workwaveBlue,
    color: theme.white,
    fontSize: '1.5rem',
    borderRadius: '50%',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
    cursor: 'pointer',
    zIndex: 5,

    '&::before': {
      content: `''`,
      position: 'absolute',
      zIndex: '-1',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      display: 'block',
      width: '100px',
      height: '100px',
      background: '#002D5C',
      borderRadius: '50%',
    },

    '&:hover': {
      '& $playButtonEffect': { transform: 'scale(1.1)' },
      '&::before': {
        animation: '$pulseBorder 1500ms ease-out infinite',
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  playButtonEffect: {
    padding: '130px',
    position: 'absolute',
    left: '97%',
    marginTop: '-100px',
    marginLeft: '-128px',
    top: '36%',
    borderRadius: '20px',
    zIndex: 5,
    transition: 'all 1s ease-in-out',
    height: '520px',
    width: '350px',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		display: 'flex',
		alignItems:'center',
		justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  heroImgBg: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: '75%',
    width: '35%',
    height: '100%',
    objectFit: 'cover',
    zIndex: '-1',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  text: {
    color: theme.workwaveBlue,
    //lineHeight: '.7',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  header: {
    lineHeight: '1.1 !important',
    color: theme.workwaveBlue,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.2rem',
    },
  },
  heroSubheader: {
    color: '#2F7FC1',
    letterSpacing: '.2px',
    lineHeight: '53px',
    fontWeight: 700,
    fontSize: '56px',
    [theme.breakpoints.down('md')]: {
      fontSize: '42px',
      lineHeight: '40px',
    },
  },
  heroBody: {
    color: '#4B5B69',
    paddingTop: '1.25rem',
    paddingBottom: '1.5rem',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1.375rem',
    lineHeight: '30px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem',
    },
  },
  heroContent: {
    position: 'relative',
    lineHeight: '28px',
    fontSize: '32px',
    color: '#F8FBFF',
    marginTop: '1rem',
    fontWeight: 600,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '22px',
      fontWeight: 600,
      left: 0,
    },
  },
  heroButton: {
    paddingBottom: '3rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    minHeight: '100%',
    minWidth: '100%',
    border: '1px solid #d8dee0',
    marginTop: '.5rem',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  nameTitleImage: {
    position: 'absolute',
    left: '-45px',
    bottom: '55px',
    zIndex: 9999,
  },
}));

export const Hero = ({ hero, setHeroModalOpen, setFormModalOpen }) => {
  const classes = useStyles();

  const md = useMediaQuery('(max-width: 960px)');
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.root}>
        {/* <div className={classes.bgWhite} /> */}
        <div className={classes.bgWhiteLight}>
          <div
            className={classes.playButtonEffect}
            style={{
              background: `url(${hero.wistiaImage?.asset?.gatsbyImageData.images.fallback.src})`,
              position: 'relative', // Ensure relative positioning for absolute positioning of the play button
            }}>
            <GatsbyImage
              image={hero.nameAndTitleImage.asset?.gatsbyImageData}
              alt="Joe King, Chief Revenue Officer"
              className={classes.nameTitleImage}
            />
            <div
              className={classes.playButton}
              onClick={(e) => setHeroModalOpen(true)}
              style={{
                cursor: 'pointer',
              }}>
              <FontAwesomeIcon icon={['fas', 'play']} size="lg" />
            </div>
          </div>
        </div>
        <GatsbyImage
          className={classes.heroImgBg}
          image={hero.backgroundImage?.asset?.gatsbyImageData}
          alt="storm"
        />
        <Container style={{ position: 'inherit' }}>
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems={md ? 'center' : 'flex-start'}
            className={classes.heroContent}
            xs={12}
            md={8}>
            {hero._rawContent.map((content, index) => (
              <PortableText
                key={index}
                content={content}
                className={classes.text}
                serializers={{
                  h1: ({ children }) => (
                    <Grid item md={11} lg={12}>
                      <Typography variant="h1" className={classes.header}>
                        {children}
                      </Typography>
                    </Grid>
                  ),
                  h4: ({ children }) => (
                    <Typography variant="h4" className={classes.heroSubheader}>
                      {children}
                    </Typography>
                  ),
                  normal: ({ children }) => {
                    return (
                      <Grid item xs={12} sm={11} md={10}>
                        <Typography
                          variant="body1"
                          className={classes.heroBody}>
                          {children}
                        </Typography>
                      </Grid>
                    );
                  },
                }}
              />
            ))}{' '}
          </Grid>
          <Grid
            item
            container
            justifyContent={md ? 'center' : 'flex-start'}
            className={classes.heroButton}>
            <Button
              color="secondary"
              size="large"
              variant="contained"
              onClick={() => setFormModalOpen(true)}>
              {hero.ctaText}
            </Button>
          </Grid>
          {md && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: '2rem' }}
              item
              xs>
              <div className={classes.playerWrapper}>
                {/* <ReactPlayer
									url={hero.wistiaLink}
									className={classes.reactPlayer}
									height='100%'
									width='100%'
									controls={true}
								/> */}
                <Video
                  url={hero.wistiaLink}
                  height="100%"
                  width="100%"
                  controls={true}
                />
              </div>
            </Grid>
          )}
        </Container>
        {/* <Grid item md={6}>
					<img
						className={classes.imgRight}
						src={hero.backgroundImage.asset.gatsbyImageData.images.fallback.src}
						alt='hero-image'
					/>
				</Grid> */}
        <WaveUpHero height="213" width="100%" fill="white" company />
      </Grid>
    </>
  );
};