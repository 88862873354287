import React from 'react';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid, 
  Typography, 
  Container, 
  useMediaQuery, 
  Card, 
  CardContent, 
} from '@material-ui/core';

import { ListItem } from '../CustomStyles/Typography';

const useStyles = makeStyles((theme) => ({
	card: {
		position: 'absolute',
		borderRadius: '20px',
    border: '1px solid #E5EAF4',
		backgroundColor: '#fff',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    zIndex: 1,
		padding: '0px',
    maxWidth: '590px',
    width: '100%',
		[theme.breakpoints.down('md')]: {
			position: 'relative',
      border: 0,
      boxShadow: 'none',
      maxWidth: '100%',
		},
	},
  header: {
		lineHeight: '1.1 !important',
		color: theme.workwaveBlue,
    fontWeight: 700,
    textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			marginTop: '2rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
		},
	},
  listStyle: {
		listStyleType: 'none',
		display: 'flex',
	},
  icon: {
		marginTop: '4px',
		marginRight: '12px',
		[theme.breakpoints.down('md')]: {},
	},
	// background: {
	// 	background:
	// 		'linear-gradient(-180deg, #002D5C -24.54%, #055291 12.46%, #2F7FC1 49.47%, #002D5C 91.02%)',
	// 	// transform: 'rotate(-180deg)',
	// 	padding: '3rem 0',
	// 	marginTop: '7rem',
	// 	[theme.breakpoints.down('md')]: {
	// 		padding: '2rem 0',
	// 		marginTop: '3rem',
	// 	},
	// },
	// header: {
	// 	fontWeight: 700,
	// 	fontSize: '3.25rem',
	// 	lineHeight: '1.1',
	// 	color: theme.white,
	// 	marginBottom: '2rem',
	// 	[theme.breakpoints.down('lg')]: {
	// 		fontSize: '3rem',
	// 	},
	// 	[theme.breakpoints.down('md')]: {
	// 		marginBottom: '1rem',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		marginBottom: '2rem',
  //     fontSize: '2rem',
  //     lineHeight: 1.2,
	// 	},
	// },
  programOffersLeft: {
    alignItems: 'flex-end',
    paddingRight: '2rem',
  },
	programOffersRight: {
		color: theme.white,
		fontWeight: 700,
    fontSize: '2.25rem',
		lineHeight: 1.2,
		letterSpacing: '.2px',
		//marginBottom: '2rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem',
      lineHeight: 1.4,
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.5rem',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	marginBottom: '2rem',
		// },
	},
	blueHighlight: {
		color: '#4DA5EE',
		fontWeight: 700,
    fontSize: '2.25rem',
		lineHeight: 1.2,
		letterSpacing: '.2px',
		//marginBottom: '2rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem',
      lineHeight: 1.4,
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.5rem',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	marginBottom: '2rem',
		// },
	},
  formPadding: {
		padding: '16rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '4rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '6rem 0',
		},
	},
	// image: {
	// 	position: 'absolute',
	// 	borderRadius: '100%',
	// 	overflow: 'hidden',
	// 	marginTop: '-4.25rem',
	// 	boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
	// 	[theme.breakpoints.down('md')]: {
	// 		marginTop: 0,
	// 		position: 'relative',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		display: 'none',
	// 	},
	// },
	// button: {
	// 	width: '45%',
	// 	[theme.breakpoints.down('md')]: {
	// 		width: '60%',
	// 	},
	// 	[theme.breakpoints.down('xs')]: {
	// 		width: '75%',
	// 	},
	// },
}));

export const ProgramOffers = ({
  ProgramOffersBackgroundImage,
	ProgramOffersLeftContent,
	ProgramOffersRightContent,
}) => {
	const classes = useStyles();
  const med = useMediaQuery('(max-width: 1024px)');
  const sm = useMediaQuery('(max-width: 600px)');
	return (
    <>
      {med ? (
        <>
          <div
            className={classes.formPadding}
            style={{
              backgroundImage: `url(${ProgramOffersBackgroundImage?.asset?.gatsbyImageData?.images.fallback.src})`,
              backgroundSize: 'cover',
              backgroundColor: '#002D5C',
            }}>
            <Container>
              <Grid
                container
                direction='row'
                justifyContent='center'
                spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={8}
                  container
                  direction='row'
                  justifyContent='center'>
                  {ProgramOffersRightContent.map((content, index) => (
                    <PortableText
                      key={index}
                      content={content}
                      className={classes.text}
                      serializers={{
                        strong: ({ children }) => (
                          <span className={classes.blueHighlight}>
                            {children}
                          </span>
                        ),
                        normal: ({ children }) => {
                          return (
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                className={classes.programOffersRight}>
                                {children}
                              </Typography>
                            </Grid>
                          );
                        },
                      }}
                    />
                  ))}{' '}
                </Grid>
              </Grid>
            </Container>
          </div>
          <Container style={{ marginBottom: '2rem'}}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              spacing={4}>
              <Grid
                item
                xs={12}
                sm={10}
                container
                direction='column'
                justifyContent='space-evenly'>
                <Card className={classes.card} elevation={0}>
                  <CardContent
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '16px',
                      justifyContent: 'space-between',
                    }}>
                      {ProgramOffersLeftContent.map((content, index) => (
                        <PortableText
                          key={index}
                          content={content}
                          className={classes.text}
                          serializers={{
                            h2: ({ children }) => (
                              <Grid item sm={12}>
                                <Typography variant='h2' className={classes.header}>
                                  {children}
                                </Typography>
                              </Grid>
                            ),
                            li: ({ children }) => (
                              <Grid item style={{ marginLeft: '-1.875rem' }}>
                                <ListItem>
                                  <li className={classes.listStyle}>
                                    <Grid>
                                      <FontAwesomeIcon
                                        icon={['fas', 'circle-check']}
                                        style={{
                                          color: '#0F95A4',
                                        }}
                                        className={classes.icon}
                                        size='lg'
                                      />
                                    </Grid>
                                    <span style={{ paddingTop: '5px'}}>{children}</span>
                                  </li>
                                </ListItem>
                              </Grid>
                            ),
                          }}
                        />
                      ))}{' '}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <div
          className={classes.formPadding}
          style={{
            backgroundImage: `url(${ProgramOffersBackgroundImage?.asset?.gatsbyImageData?.images.fallback.src})`,
            backgroundSize: 'cover',
            backgroundColor: '#002D5C',
          }}>
          <Container>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              spacing={4}
              style={{ position: 'relative', }}>
              <Grid
                item
                xs={12}
                md={6}
                container
                direction='column'
                justifyContent='space-evenly'>
                <Card className={classes.card} elevation={0}>
                  <CardContent
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '60px 16px',
                      justifyContent: 'space-between',
                    }}>
                      {ProgramOffersLeftContent.map((content, index) => (
                        <PortableText
                          key={index}
                          content={content}
                          className={classes.text}
                          serializers={{
                            h2: ({ children }) => (
                              <Grid item sm={12}>
                                <Typography variant='h2' className={classes.header}>
                                  {children}
                                </Typography>
                              </Grid>
                            ),
                            li: ({ children }) => (
                              <Grid item style={{ marginLeft: '-1.875rem' }}>
                                <ListItem>
                                  <li className={classes.listStyle}>
                                    <Grid>
                                      <FontAwesomeIcon
                                        icon={['fas', 'circle-check']}
                                        style={{
                                          color: '#0F95A4',
                                        }}
                                        className={classes.icon}
                                        size='lg'
                                      />
                                    </Grid>
                                    <span style={{ paddingTop: '5px'}}>{children}</span>
                                  </li>
                                </ListItem>
                              </Grid>
                            ),
                          }}
                        />
                      ))}{' '}
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                container
                direction='row'
                justifyContent='center'
                style={{ display: sm ? 'none' : ''}}>
                {ProgramOffersRightContent.map((content, index) => (
                  <PortableText
                    key={index}
                    content={content}
                    className={classes.text}
                    serializers={{
                      strong: ({ children }) => (
                        <span className={classes.blueHighlight}>
                          {children}
                        </span>
                      ),
                      normal: ({ children }) => {
                        return (
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              className={classes.programOffersRight}>
                              {children}
                            </Typography>
                          </Grid>
                        );
                      },
                    }}
                  />
                ))}{' '}
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
	);
};
