import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Grid, useMediaQuery, Typography } from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		marginBottom: '3rem',
// 	},
// }));

export const PrivateEquityLogos = ({ privateEquityLogosArray, privateEquityLogosHeader }) => {
	const md = useMediaQuery('(max-width: 960px)');
  const sm = useMediaQuery('(max-width: 600px)');
	return (
		<div style={{ padding: sm ? '3rem 0' : md ? '0 3rem 5rem' : '0 5rem' }}>
			{!!privateEquityLogosHeader ? (
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
					style={{ textAlign: 'center', padding: '2rem 0' }}>
					<Grid item xs={10} md={12} lg={8}>
						<Typography
							variant='h2'
							style={{
								fontWeight: 700,
								color: '#002D5C',
								// fontSize: '1.375rem',
							}}>
							{privateEquityLogosHeader}
						</Typography>
					</Grid>
				</Grid>
			) : null}
			<Grid
				// style={{
				// 	padding: '1rem 0',
				// 	border: '1px solid #fffff',
				// 	boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
				// 	borderRadius: '8px',
				// }}
				container
				direction='row'
				justifyContent='space-around'
				alignItems='center'
				spacing={4}>
				{privateEquityLogosArray.map((privateEquityLogo, index) => (
					<Grid
						container
						direction='row'
						justifyContent='center'
						item
						xs={12}
            sm={6}
						md={4}
						key={index}>
						<GatsbyImage
							image={privateEquityLogo.firmLogo.asset.gatsbyImageData}
							alt={privateEquityLogo.firmLogo.title}
							//THIS IS WHERE THE IMAGES ARE TOO BIG
							// style={{ width: '425px' }}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};
