import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid, 
  Typography, 
  Container, 
  useMediaQuery, 
} from '@material-ui/core';

import { ListItem } from '../CustomStyles/Typography';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.workwaveBlue,
		textAlign: 'left',
    fontWeight: 500,
		//lineHeight: '.7',
	},
  header: {
		lineHeight: '1.1 !important',
		color: theme.workwaveBlue,
    textAlign: 'left',
    marginBottom: '2rem',
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
		},
	},
  listStyle: {
		listStyleType: 'none',
		display: 'flex',
	},
  intro: {
    fontFamily: 'Roboto',
    fontSize: '1.25rem',
    marginBottom: '1rem',
  }
}));

export const WhyWorkWave = ({
  WhyWorkWaveBody,
	WhyWorkWaveImage,
}) => {
	const classes = useStyles();
  const md = useMediaQuery('(max-width: 960px)');
	return (
    <Container>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        spacing={4}>
        <Grid
          item
          xs={12}
          lg={6}
          container
          direction='row'
          justifyContent='center'>
						<GatsbyImage
							image={WhyWorkWaveImage?.asset.gatsbyImageData}
              style={{ maxWidth: md ? '500px' : 'initial' }}
						/>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          container
          direction='row'>
          {WhyWorkWaveBody.map((content, index) => (
            <PortableText
              key={index}
              content={content}
              className={classes.text}
              serializers={{
                h2: ({ children }) => (
                  <Grid item sm={12}>
                    <Typography variant='h2' className={classes.header}>
                      {children}
                    </Typography>
                  </Grid>
                ),
								li: ({ children }) => (
									<Grid item>
										<ListItem>
											<li>{children}</li>
										</ListItem>
									</Grid>
								),
                normal: ({ children }) => {
                  return (
                    <Grid item xs={12}>
                      <Typography
                        variant='body1'
                        className={classes.intro}>
                        {children}
                      </Typography>
                    </Grid>
                  );
                },
              }}
            />
          ))}{' '}
        </Grid>
      </Grid>
    </Container>
	);
};
